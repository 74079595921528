

































































































































































import * as _ from 'lodash'
import * as XLSX from 'xlsx'
import {Component, Vue, Watch} from 'vue-property-decorator'
import {RequestPaging} from '@/utils/util'
import {
  apiDelSelectMaterial,
  apiGetAllMaterialForTopic,
  apiGetFormatTopic,
  apiGetMaterialByIds,
  apiGoodsLists,
  apiSetTopOrderAll
} from '@/api/goods'
import LsPagination from '@/components/ls-pagination.vue'
import {filterTableData} from '@/utils/table'
import Template from "@/views/shop/pages/template.vue";
import {commonAlert} from "@/utils/confirm";

@Component({
  components: {
    Template,
    LsPagination
  }
})
export default class MaterialManage extends Vue {
  // 分页
  $refs!: { table: any }
  pager: RequestPaging = new RequestPaging()
  materialList: any = []
  // 选中的集合
  multipleSelection: any = []
  // 选中的索引
  multipleIndex: any = []
  // 获取所有主题
  topicList: any = []
  isStatus: boolean = false
  // // 是否在最上
  // isHaveTop: boolean = false
  // // 是否在最下
  // isHaveBottom: boolean = false
  // 默认全都关闭
  isFalse: boolean = true
  isTopicMaterial: boolean = true
  form: any = {
    keyword: '',
    category_id: '',
    supplier_id: '',
    goods_device_size: '',
    topic_id: ''
  }

  // 所属主题筛选
  get filtersTopicName() {
    return filterTableData(this.materialList, 'topic_name')
  }

  // 创建人筛选
  get filtersCreateName() {
    return filterTableData(this.materialList, 'create_name')
  }

  // 最后修改人筛选
  get filtersLastName() {
    return filterTableData(this.materialList, 'last_name')
  }

  isOnline(scope: any) {
    if (scope.row.goods_topic_index_one) {
      return scope.row.goods_topic_index_one.online_status === 1
    }
  }

  // 移动默认是关闭的
  @Watch('multipleSelection')
  onMultipleSelectionChange(newVal: any) {
    this.isFalse = newVal.length <= 0;
  }

  // // 判断是否有最上边或者最下面的
  // @Watch('multipleIndex')
  // onMultipleIndexChange(newVal: any) {
  //   if (newVal.some((item: any) => item === 0)) {
  //     this.isHaveTop = true
  //   } else {
  //     this.isHaveTop = false
  //   }
  //   const index = this.materialList.length - 1
  //   if (newVal.some((item: any) => item === index)) {
  //     this.isHaveBottom = true
  //   } else {
  //     this.isHaveBottom = false
  //   }
  // }

  // 所属主题筛选
  filterMethodCreateName(value: any, row: any) {
    return row.create_name === value
  }

  // 创建人筛选
  filterMethodTopicName(value: any, row: any) {
    return row.topic_name === value
  }

  // 最后修改人筛选
  filterMethodLastName(value: any, row: any) {
    return row.last_name === value
  }

  created() {
    // 若 query 里有topicID，则当前页面是主题下的素材页面
    if (this.$route.query.topicId) {
      this.form.topic_id = this.$route.query.topicId
      this.isStatus = Boolean(this.$route.query.status)
      this.isTopicMaterial = false
      this.getAllMaterialForTopic(this.form.topic_id)
    } else {
      this.getMaterialLists()
      this.getTopic('')
    }
  }

  rowStyle() {
    return 'text-align:center'
  }

  // 删除某行
  deleteRow(ids: any) {
    this.$confirm('确定删除选中的素材吗?', '删除提示').then(async () => {
      await apiDelSelectMaterial({
        ids: [ids]
      })
      await this.getMaterialLists()
      await this.$message({
        type: 'success',
        message: '删除成功!'
      })
    }).catch(() => {
    })
  }

  // 批量删除
  async delSelectTopic() {
    if (this.multipleSelection.length === 0) {
      return this.$message.error('请选中您想删除的素材！')
    }
    // let status = true
    // this.multipleSelection.forEach((item: any) => {
    //   if (item.goods_topic_index_one.online_status == 1) return status = false
    // })
    const isOnline = this.multipleSelection.some((item: any) => {
      // 前者是为了避免当素材没有关联主题时报错
      return item.goods_topic_index_one && item.goods_topic_index_one.online_status == 1
    })
    if (isOnline) {
      return commonAlert('您选中的素材中含有所在主题已发布的素材，不能删除！', '删除提示')
    }
    this.$confirm(`确定删除选中的${this.multipleSelection.length}个素材吗?`, '删除提示').then(async () => {
      await apiDelSelectMaterial({
        ids: this.multipleSelection.map((item: any) => item.id)
      })
      await this.getMaterialLists()
      await this.$message({
        type: 'success',
        message: '删除成功!'
      })
    }).catch(() => {
    })
  }

  // 选中的时候的状态
  handleSelectionChange(val: any) {
    this.multipleSelection = val
    const index: any = []
    this.multipleSelection.forEach((val: any) => {
      this.materialList.forEach((v: any, i: any) => {
        if (val.id === v.id) {
          index.push(i)
        }
      })
    })
    this.multipleIndex = _.sortBy(index)
  }

  // 编辑单行
  editRow(id: any) {
    const ids = [{id: id}]
   
    apiGetMaterialByIds(ids).then((res) => {
     
      if (this.$route.params.data) {
        this.$router.push({
          name: 'new_material',
          params: {
            data: res,
            status: 'edit'
          }
        })
      } else {
        this.$router.push({
          name: 'new_material',
          params: {
            data: res
          }
        })
      }
    })
  }

  // 批量编辑
  goEdit() {
    if (this.multipleSelection.length === 0) {
      return this.$message.error('请选中您想编辑的主题！')
    }
    // let status = true
    // this.multipleSelection.forEach((item: any) => {
    //   // if的前者是为了避免当素材没有关联主题时报错
    //   if (item.goods_topic_index_one && item.goods_topic_index_one.online_status == 1) return status = false
    // })
    const isOnline = this.multipleSelection.some((item: any) => {
      // 前者是为了避免当素材没有关联主题时报错
      return item.goods_topic_index_one && item.goods_topic_index_one.online_status == 1
    })
    if (isOnline) {
      return commonAlert('您选中的素材中含有所在主题已发布的素材，不能编辑！', '编辑提示')
    }
    const ids = this.multipleSelection.map((item: any) => ({id: item.id}))
    apiGetMaterialByIds(ids).then((res) => {
      this.$router.push({
        name: 'new_material',
        params: {
          data: res
        }
      })
    })
  }

  // 去编辑页
  goNewMaterial() {
    this.$router.push({
      path: '/content/new_material'
    })
  }

  exportFile() {
    if (this.multipleSelection.length <= 0) {
      this.$message.error('请选中您想编辑的主题！')
      return
    }
    let sheetData = this.multipleSelection.map((item:any) => ({
      ["素材名称"]: item.name,
      ["封面图片"]: item.image,
      ["素材描述"]: item.content,
      ["所属主题"]: item.topic_name,
      ["行数"]: item.row_num,
      ["列数"]: item.col_num,
      ["创建时间"]: item.create_time,
      ["创建人"]: item.create_name,
      ["最后修改时间"]: item.update_time,
      ["最后修改人"]: item.last_name,
    }))

    // 将workbook装化成blob对象
    function workbook2blob(workbook: any) {
      // 生成excel的配置项
      let wopts:Object = {
        // 要生成的文件类型
        bookType: "xlsx",
        // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        bookSST: false,
        type: "binary"
      };
      let wbout = XLSX.write(workbook, wopts);

      // 将字符串转ArrayBuffer
      function s2ab(s: any) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      return new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
      });
    }

    // 将blob对象创建bloburl，然后用a标签实现弹出下载框
    function openDownloadDialog(blob: any, fileName: any) {
      if (typeof blob == "object" && blob instanceof Blob) {
        blob = URL.createObjectURL(blob); // 创建blob地址
      }
      let aLink = document.createElement("a");
      aLink.href = blob;
      // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
      aLink.download = fileName || "";
      let event;
      if (window.MouseEvent) event = new MouseEvent("click");
      //   移动端
      else {
        event = document.createEvent("MouseEvents");
        event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      }
      aLink.dispatchEvent(event);
    }

    let sheet = XLSX.utils.json_to_sheet(sheetData);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "部门统计");
    const workbookBlob = workbook2blob(wb);
    // 点击下载
    openDownloadDialog(workbookBlob, `素材管理.xlsx`);
  }

  // 配合table，使得页面刷新不会取消勾选
  getRowKey(row: any) {
    return row.id
  }

  // 获取所有主题
  async getTopic(value: any) {
    const {
      audio,
      calendar,
      paint
    } = await apiGetFormatTopic({
      search: value
    })
    this.topicList = [{
      label: '画册模式',
      options: {...paint}
    }, {
      label: '音箱模式',
      options: {...audio}
    }, {
      label: '台历模式',
      options: {...calendar}
    }]
  }

  // 获取搜索的素材列表
  async getSearchLists() {
    this.pager.request({
      callback: apiGoodsLists,
      params: {
        ...this.form,
        is_search_engine: 1
      }
    }).then((res: any) => {
      this.materialList = res.lists
    })
    this.$nextTick(() => {
      this.$refs.table.clearSelection()
    })
  }

  // 获取素材列表
  async getMaterialLists() {
    this.pager.request({
      callback: apiGoodsLists,
      params: {
        ...this.form
      }
    }).then((res: any) => {
      this.materialList = res.lists
    })
    this.$nextTick(() => {
      this.$refs.table.clearSelection()
    })
  }

  // 获取主题下所有素材列表
  async getAllMaterialForTopic(topicId: string) {
    await apiGetAllMaterialForTopic({
      topic_id: topicId
    }).then(res => {
      this.materialList = res
    })
  }

  // 根据id获取索引
  getIndexById(data: any) {
    let selectIndex: any = []
    data.forEach((item: any) => {
      selectIndex.push(this.materialList.findIndex((i: any) => i.id == item.id))
    })
    return selectIndex
  }

  /**
   * 先获取选中的索引
   * 不用element ui的多选索引是因为不触发选中，索引就不会更新
   * 所以要自己获取
   * @param type 1.置顶，0置底
   */
  async setTopORBottom(type: number) {
    const selectIndex: any = this.getIndexById(this.multipleSelection)
    // 根据索引遍历，删除索引所在的值，存入data中
    let data: any = []
    let tableData: any = this.materialList
    selectIndex.forEach((item: any) => {
      data.push(tableData[item])
      delete tableData[item]
    })
    // delete会删除索引把索引变空，用filter去掉empty
    tableData = tableData.filter((item: any) => item)
    data.forEach((item: any) => {
      if (type === 1) {
        tableData.unshift(item)
      } else if (type === 0) {
        tableData.push(item)
      }
    })
    await this.setLocation(tableData)
  }

  /**
   * 移动
   * @param type 1.上移，0下移
   */
  async move(type: number) {
    let selectIndex: any = this.getIndexById(this.multipleSelection)
    // 获取表格所有数据
    const tableData: any = this.materialList
    // 如果上移，索引正序排序，反之倒序排序
    selectIndex = type === 1 ? selectIndex.sort() : selectIndex.sort((a: any, b: any) => b - a)
    selectIndex.forEach((index: number) => {
      let neighbor: any = type === 1 ? index - 1 : index + 1
      if (neighbor < 0) {
        neighbor = 0
      } else if (neighbor > tableData.length - 1) {
        neighbor = tableData.length - 1
      }
      let data = tableData[neighbor]
      tableData[neighbor] = tableData[index]
      tableData[index] = data
    })
    await this.setLocation(tableData)
  }

  // 根据录入的排序字段更新
  async setLocation(data: any) {
    const order_data: any = []
    data.forEach((item: any) => {
      order_data.push({
        id: item.id
      })
    })
    await apiSetTopOrderAll({
      order_data,
      topic_id: this.form.topic_id
    })
    await this.getMaterialLists()
  }
}
